// Note: Git Hooks (using by husky and lint-staged) will change isEnabled to false every commit.
// This ensures developers always start off pointing at live APIs and not mocks.

import { CMS_PATHS } from './API';
import { OFFICES } from './COUNTRIES';

const DELAY = 0;

const ACCOUNT_ERROR_ENABLED = false;

const AUTH_ERROR_ENABLED = false;

const BOOKINGS_ENABLED = false;

const VOYAGE_UNDER_14_DAYS_ENABLED = false;

const CMS_PATHS_ENABLED = Object.freeze({
  [OFFICES.AU]: {
    faq: { cmsPath: CMS_PATHS.faq, isEnabled: false },
  },
  [OFFICES.US]: {
    faq: { cmsPath: CMS_PATHS.faq, isEnabled: false },
  },
});

const CMS_PAGES_PATHS_ENABLED = Object.freeze({
  [OFFICES.AU]: {
    academy: { cmsPath: CMS_PATHS.pages.academy, isEnabled: false },
    account: { cmsPath: CMS_PATHS.pages.account, isEnabled: false },
    advertising: { cmsPath: CMS_PATHS.pages.advertising, isEnabled: false },
    bookingCreate: { cmsPath: CMS_PATHS.pages.bookingCreate, isEnabled: false },
    bookingPersonal: { cmsPath: CMS_PATHS.pages.bookingPersonal, isEnabled: false },
    bookings: { cmsPath: CMS_PATHS.pages.bookings, isEnabled: false },
    carrierInfo: { cmsPath: CMS_PATHS.pages.carrierInfo, isEnabled: false },
    common: { cmsPath: CMS_PATHS.pages.common, isEnabled: false },
    contact: { cmsPath: CMS_PATHS.pages.contact, isEnabled: false },
    faq: { cmsPath: CMS_PATHS.pages.faq, isEnabled: false },
    formsGuides: { cmsPath: CMS_PATHS.pages.formsGuides, isEnabled: false },
    groups: { cmsPath: CMS_PATHS.pages.groups, isEnabled: false },
    home: { cmsPath: CMS_PATHS.pages.home, isEnabled: false },
    marketing: { cmsPath: CMS_PATHS.pages.marketing, isEnabled: false },
    notFound: { cmsPath: CMS_PATHS.pages.notFound, isEnabled: false },
    platinumClub: { cmsPath: CMS_PATHS.pages.platinumClub, isEnabled: false },
    resources: { cmsPath: CMS_PATHS.pages.resources, isEnabled: false },
    rewards: { cmsPath: CMS_PATHS.pages.rewards, isEnabled: false },
    security: { cmsPath: CMS_PATHS.pages.security, isEnabled: false },
    siteMap: { cmsPath: CMS_PATHS.pages.siteMap, isEnabled: false },
    terms: { cmsPath: CMS_PATHS.pages.terms, isEnabled: false },
    training: { cmsPath: CMS_PATHS.pages.training, isEnabled: false },
    website: { cmsPath: CMS_PATHS.pages.website, isEnabled: false },
  },
  [OFFICES.CA]: {
    academy: { cmsPath: CMS_PATHS.pages.academy, isEnabled: false },
    account: { cmsPath: CMS_PATHS.pages.account, isEnabled: false },
    advertising: { cmsPath: CMS_PATHS.pages.advertising, isEnabled: false },
    bookingCreate: { cmsPath: CMS_PATHS.pages.bookingCreate, isEnabled: false },
    bookingPersonal: { cmsPath: CMS_PATHS.pages.bookingPersonal, isEnabled: false },
    bookings: { cmsPath: CMS_PATHS.pages.bookings, isEnabled: false },
    carrierInfo: { cmsPath: CMS_PATHS.pages.carrierInfo, isEnabled: false },
    common: { cmsPath: CMS_PATHS.pages.common, isEnabled: false },
    contact: { cmsPath: CMS_PATHS.pages.contact, isEnabled: false },
    faq: { cmsPath: CMS_PATHS.pages.faq, isEnabled: false },
    formsGuides: { cmsPath: CMS_PATHS.pages.formsGuides, isEnabled: false },
    groups: { cmsPath: CMS_PATHS.pages.groups, isEnabled: false },
    home: { cmsPath: CMS_PATHS.pages.home, isEnabled: false },
    marketing: { cmsPath: CMS_PATHS.pages.marketing, isEnabled: false },
    notFound: { cmsPath: CMS_PATHS.pages.notFound, isEnabled: false },
    platinumClub: { cmsPath: CMS_PATHS.pages.platinumClub, isEnabled: false },
    resources: { cmsPath: CMS_PATHS.pages.resources, isEnabled: false },
    rewards: { cmsPath: CMS_PATHS.pages.rewards, isEnabled: false },
    security: { cmsPath: CMS_PATHS.pages.security, isEnabled: false },
    siteMap: { cmsPath: CMS_PATHS.pages.siteMap, isEnabled: false },
    terms: { cmsPath: CMS_PATHS.pages.terms, isEnabled: false },
    training: { cmsPath: CMS_PATHS.pages.training, isEnabled: false },
    website: { cmsPath: CMS_PATHS.pages.website, isEnabled: false },
  },
  [OFFICES.US]: {
    academy: { cmsPath: CMS_PATHS.pages.academy, isEnabled: false },
    account: { cmsPath: CMS_PATHS.pages.account, isEnabled: false },
    advertising: { cmsPath: CMS_PATHS.pages.advertising, isEnabled: false },
    bookingCreate: { cmsPath: CMS_PATHS.pages.bookingCreate, isEnabled: false },
    bookingPersonal: { cmsPath: CMS_PATHS.pages.bookingPersonal, isEnabled: false },
    bookings: { cmsPath: CMS_PATHS.pages.bookings, isEnabled: false },
    carrierInfo: { cmsPath: CMS_PATHS.pages.carrierInfo, isEnabled: false },
    common: { cmsPath: CMS_PATHS.pages.common, isEnabled: false },
    contact: { cmsPath: CMS_PATHS.pages.contact, isEnabled: false },
    faq: { cmsPath: CMS_PATHS.pages.faq, isEnabled: false },
    formsGuides: { cmsPath: CMS_PATHS.pages.formsGuides, isEnabled: false },
    groups: { cmsPath: CMS_PATHS.pages.groups, isEnabled: false },
    home: { cmsPath: CMS_PATHS.pages.home, isEnabled: false },
    marketing: { cmsPath: CMS_PATHS.pages.marketing, isEnabled: false },
    notFound: { cmsPath: CMS_PATHS.pages.notFound, isEnabled: false },
    platinumClub: { cmsPath: CMS_PATHS.pages.platinumClub, isEnabled: false },
    resources: { cmsPath: CMS_PATHS.pages.resources, isEnabled: false },
    rewards: { cmsPath: CMS_PATHS.pages.rewards, isEnabled: false },
    security: { cmsPath: CMS_PATHS.pages.security, isEnabled: false },
    siteMap: { cmsPath: CMS_PATHS.pages.siteMap, isEnabled: false },
    terms: { cmsPath: CMS_PATHS.pages.terms, isEnabled: false },
    training: { cmsPath: CMS_PATHS.pages.training, isEnabled: false },
    website: { cmsPath: CMS_PATHS.pages.website, isEnabled: false },
  },
  [OFFICES.UK]: {
    academy: { cmsPath: CMS_PATHS.pages.academy, isEnabled: false },
    account: { cmsPath: CMS_PATHS.pages.account, isEnabled: false },
    advertising: { cmsPath: CMS_PATHS.pages.advertising, isEnabled: false },
    bookingCreate: { cmsPath: CMS_PATHS.pages.bookingCreate, isEnabled: false },
    bookingPersonal: { cmsPath: CMS_PATHS.pages.bookingPersonal, isEnabled: false },
    bookings: { cmsPath: CMS_PATHS.pages.bookings, isEnabled: false },
    carrierInfo: { cmsPath: CMS_PATHS.pages.carrierInfo, isEnabled: false },
    common: { cmsPath: CMS_PATHS.pages.common, isEnabled: false },
    contact: { cmsPath: CMS_PATHS.pages.contact, isEnabled: false },
    faq: { cmsPath: CMS_PATHS.pages.faq, isEnabled: false },
    formsGuides: { cmsPath: CMS_PATHS.pages.formsGuides, isEnabled: false },
    groups: { cmsPath: CMS_PATHS.pages.groups, isEnabled: false },
    home: { cmsPath: CMS_PATHS.pages.home, isEnabled: false },
    marketing: { cmsPath: CMS_PATHS.pages.marketing, isEnabled: false },
    notFound: { cmsPath: CMS_PATHS.pages.notFound, isEnabled: false },
    platinumClub: { cmsPath: CMS_PATHS.pages.platinumClub, isEnabled: false },
    resources: { cmsPath: CMS_PATHS.pages.resources, isEnabled: false },
    rewards: { cmsPath: CMS_PATHS.pages.rewards, isEnabled: false },
    security: { cmsPath: CMS_PATHS.pages.security, isEnabled: false },
    siteMap: { cmsPath: CMS_PATHS.pages.siteMap, isEnabled: false },
    terms: { cmsPath: CMS_PATHS.pages.terms, isEnabled: false },
    training: { cmsPath: CMS_PATHS.pages.training, isEnabled: false },
    website: { cmsPath: CMS_PATHS.pages.website, isEnabled: false },
  },
});

const isEnabled =
  ACCOUNT_ERROR_ENABLED ||
  AUTH_ERROR_ENABLED ||
  BOOKINGS_ENABLED ||
  VOYAGE_UNDER_14_DAYS_ENABLED ||
  Object.values(CMS_PAGES_PATHS_ENABLED).some((value) => Object.values(value).some((v) => v.isEnabled)) ||
  Object.values(CMS_PATHS_ENABLED).some((value) => Object.values(value).some((v) => v.isEnabled));

export {
  ACCOUNT_ERROR_ENABLED,
  AUTH_ERROR_ENABLED,
  BOOKINGS_ENABLED,
  CMS_PAGES_PATHS_ENABLED,
  CMS_PATHS_ENABLED,
  DELAY,
  isEnabled,
  VOYAGE_UNDER_14_DAYS_ENABLED,
};
