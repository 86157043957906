import cryptoRandomString from 'crypto-random-string';
import { nanoid } from 'nanoid';
import { base64Encode } from './string';

const getTraceparent = () => {
  const version = '00';
  const traceId = cryptoRandomString({ length: 16 });
  const id = cryptoRandomString({ length: 8 });
  const flags = '00';
  return `${version}-${traceId}-${id}-${flags}`;
};

const objectToQueryString = (obj = {}) => {
  const queryString = Object.entries(obj)
    ?.filter(([, value]) => !!value)
    ?.map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    ?.join('&');
  return queryString ? `?${queryString}` : '';
};

const getRequestHeaders = ({ appInsightsSessionId, auth, token }) => {
  const { agency, agent, msal } = auth;
  const headers = {};
  if (msal?.idTokenClaims?.sub && token) {
    headers.Authorization = `Bearer ${token}`;
    headers.AccountId = msal.idTokenClaims.sub;
    headers.Email = (agent.email || '').trim().toLowerCase();
    headers['Evo-Agent-IATA'] = agency.agencyIata || '';
    headers['Evo-Agency-ID'] = agency.agencyCode || '';
    headers['Evo-Agent-Email'] = (agent.email || '').trim().toLowerCase();
    headers['Evo-Agent-Name'] = `${agent.firstName} ${agent.lastName}`
      .replace(/[^a-zA-Z\s-,.@&"]/gi, '') // illegal characters
      .replace(/["]/gi, '\\$&') // escapable characters
      .trim();
    headers['Evo-UI-Code'] = 'A';
    headers['Evo-GDS-Code'] = 'TC';
  }
  const traceparent = getTraceparent();
  const requestGroupId = nanoid();

  const appinsights = base64Encode(JSON.stringify({ requestGroupId, sessionId: appInsightsSessionId }));
  headers.appinsights = appinsights;
  headers.traceparent = traceparent;
  headers['Content-Type'] = 'application/json';
  return headers;
};

export { getRequestHeaders, getTraceparent, objectToQueryString };
