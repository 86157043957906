import { TAP_API_BASE_URL } from 'constants/ENV';
import { DELAY, VOYAGE_UNDER_14_DAYS_ENABLED } from 'constants/MOCK_CONFIG';
import { HttpResponse, delay, http } from 'msw';

const handlers = [];

if (VOYAGE_UNDER_14_DAYS_ENABLED) {
  const voyageUrl = `${TAP_API_BASE_URL}/voyages/*`;

  handlers.push(
    http.get(voyageUrl, async () => {
      await delay(DELAY);
      return HttpResponse.json({
        voyageData: [
          {
            cruiseTour: false,
            currency: 'USD',
            departPort: 'Paris',
            departPortCode: 'PAR',
            departPortName: 'Paris, France',
            hasOfferCode: false,
            isAlreadyBooked: true,
            isBookingEngine: true,
            isPublicSite: true,
            isSoldOut: false,
            priceAir: {
              from: 799,
            },
            priceVoyage: {
              brochure: 3999,
              singleFrom: 6998,
              doubleFrom: 3499,
            },
            returnPort: 'Paris',
            returnPortCode: 'PAR',
            returnPortName: 'Paris, France',
            shipId: 'RGD',
            shipInfo: {
              shipId: 'RGD',
              shipName: 'Viking Radgrid',
              shipType: 'seine',
              shipTypePathId: 'seine-ships',
            },
            shipName: 'Viking Radgrid',
            voyageDirection: 'Paris to Paris',
            voyageFromDate: '2024-12-10',
            voyageId: 'RGD241210',
            voyageLength: 7,
            voyageName: 'Christmas on the Seine',
            voyageToDate: '2024-12-17',
          },
          {
            cruiseTour: false,
            currency: 'USD',
            departPort: 'Paris',
            departPortCode: 'PAR',
            departPortName: 'Paris, France',
            hasOfferCode: false,
            isAlreadyBooked: true,
            isBookingEngine: true,
            isPublicSite: true,
            isSoldOut: false,
            priceAir: {
              from: 799,
            },
            priceVoyage: {
              brochure: 6299,
              singleFrom: 10598,
              doubleFrom: 5299,
            },
            returnPort: 'Paris',
            returnPortCode: 'PAR',
            returnPortName: 'Paris, France',
            shipId: 'KRI',
            shipInfo: {
              shipId: 'KRI',
              shipName: 'Viking Kari',
              shipType: 'seine',
              shipTypePathId: 'seine-ships',
            },
            shipName: 'Viking Kari',
            voyageDirection: 'Paris to Paris',
            voyageFromDate: '2024-12-11',
            voyageId: 'KRI241211',
            voyageLength: 7,
            voyageName: 'Christmas on the Seine',
            voyageToDate: '2024-12-18',
          },
          {
            cruiseTour: false,
            currency: 'USD',
            departPort: 'Paris',
            departPortCode: 'PAR',
            departPortName: 'Paris, France',
            hasOfferCode: false,
            isAlreadyBooked: true,
            isBookingEngine: true,
            isPublicSite: true,
            isSoldOut: true,
            priceAir: {
              from: 799,
            },
            priceVoyage: {
              brochure: null,
              singleFrom: null,
              doubleFrom: null,
            },
            returnPort: 'Paris',
            returnPortCode: 'PAR',
            returnPortName: 'Paris, France',
            shipId: 'SKG',
            shipInfo: {
              shipId: 'SKG',
              shipName: 'Viking Skaga',
              shipType: 'seine',
              shipTypePathId: 'seine-ships',
            },
            shipName: 'Viking Skaga',
            voyageDirection: 'Paris to Paris',
            voyageFromDate: '2024-12-12',
            voyageId: 'SKG241212',
            voyageLength: 7,
            voyageName: 'Christmas on the Seine',
            voyageToDate: '2024-12-19',
          },
          {
            cruiseTour: false,
            currency: 'USD',
            departPort: 'Paris',
            departPortCode: 'PAR',
            departPortName: 'Paris, France',
            hasOfferCode: false,
            isAlreadyBooked: true,
            isBookingEngine: true,
            isPublicSite: true,
            isSoldOut: true,
            priceAir: {
              from: 799,
            },
            priceVoyage: {
              brochure: null,
              singleFrom: null,
              doubleFrom: null,
            },
            returnPort: 'Paris',
            returnPortCode: 'PAR',
            returnPortName: 'Paris, France',
            shipId: 'RGD',
            shipInfo: {
              shipId: 'RGD',
              shipName: 'Viking Radgrid',
              shipType: 'seine',
              shipTypePathId: 'seine-ships',
            },
            shipName: 'Viking Radgrid',
            voyageDirection: 'Paris to Paris',
            voyageFromDate: '2024-12-17',
            voyageId: 'RGD241217',
            voyageLength: 7,
            voyageName: 'Christmas on the Seine',
            voyageToDate: '2024-12-24',
          },
          {
            cruiseTour: false,
            currency: 'USD',
            departPort: 'Paris',
            departPortCode: 'PAR',
            departPortName: 'Paris, France',
            hasOfferCode: false,
            isAlreadyBooked: true,
            isBookingEngine: true,
            isPublicSite: true,
            isSoldOut: true,
            priceAir: {
              from: 799,
            },
            priceVoyage: {
              brochure: null,
              singleFrom: null,
              doubleFrom: null,
            },
            returnPort: 'Paris',
            returnPortCode: 'PAR',
            returnPortName: 'Paris, France',
            shipId: 'KRI',
            shipInfo: {
              shipId: 'KRI',
              shipName: 'Viking Kari',
              shipType: 'seine',
              shipTypePathId: 'seine-ships',
            },
            shipName: 'Viking Kari',
            voyageDirection: 'Paris to Paris',
            voyageFromDate: '2024-12-18',
            voyageId: 'KRI241218',
            voyageLength: 7,
            voyageName: 'Christmas on the Seine',
            voyageToDate: '2024-12-25',
          },
          {
            cruiseTour: false,
            currency: 'USD',
            departPort: 'Paris',
            departPortCode: 'PAR',
            departPortName: 'Paris, France',
            hasOfferCode: false,
            isAlreadyBooked: true,
            isBookingEngine: true,
            isPublicSite: true,
            isSoldOut: false,
            priceAir: {
              from: 799,
            },
            priceVoyage: {
              brochure: 4199,
              singleFrom: 7398,
              doubleFrom: 3699,
            },
            returnPort: 'Paris',
            returnPortCode: 'PAR',
            returnPortName: 'Paris, France',
            shipId: 'SKG',
            shipInfo: {
              shipId: 'SKG',
              shipName: 'Viking Skaga',
              shipType: 'seine',
              shipTypePathId: 'seine-ships',
            },
            shipName: 'Viking Skaga',
            voyageDirection: 'Paris to Paris',
            voyageFromDate: '2024-12-19',
            voyageId: 'SKG241219',
            voyageLength: 7,
            voyageName: 'Christmas on the Seine',
            voyageToDate: '2024-12-26',
          },
        ],
        shipTypes: {
          'seine-ships': {
            jcrName: 'seine-ships',
            stateroomCategories: [],
          },
          seine: {
            jcrName: 'seine',
            stateroomCategories: [
              {
                categoryId: ['ES'],
                header: '<p>Explorer Suite (ES)</p>',
                parentCategory: 'suite',
                title: '<p>Explorer Suite</p>',
              },
              {
                categoryId: ['C', 'D'],
                header: '<p>French Balcony Stateroom (C, D)</p>',
                parentCategory: 'french_balcony',
                title: '<p>French Balcony Stateroom</p>',
              },
              {
                categoryId: ['E', 'F'],
                header: '<p>Standard Stateroom (E, F)</p>',
                parentCategory: 'standard',
                title: '<p>Standard Stateroom</p>',
              },
              {
                categoryId: ['A', 'B'],
                header: '<p>Veranda Stateroom (A, B)</p>',
                parentCategory: 'veranda',
                title: '<p>Veranda Stateroom</p>',
              },
              {
                categoryId: ['AA'],
                header: '<p>Veranda Suite (AA)</p>',
                parentCategory: 'suite',
                title: '<p>Veranda Suite</p>',
              },
            ],
          },
        },
        stateroomCategories: [
          {
            ids: ['ES'],
            bucket: 'SUITE',
            name: 'Explorer Suite (ES)',
          },
          {
            ids: ['C', 'D'],
            bucket: 'FRENCH BALCONY',
            name: 'French Balcony Stateroom (C, D)',
          },
          {
            ids: ['E', 'F'],
            bucket: 'STANDARD',
            name: 'Standard Stateroom (E, F)',
          },
          {
            ids: ['A', 'B'],
            bucket: 'VERANDA',
            name: 'Veranda Stateroom (A, B)',
          },
          {
            ids: ['AA'],
            bucket: 'SUITE',
            name: 'Veranda Suite (AA)',
          },
        ],
      });
    })
  );
}

export default handlers;
